import en from './lang/en.json';
import de from './lang/de.json';
import fr from './lang/fr.json';
import it from './lang/it.json';

export default defineI18nConfig(() => ({
  legacy: false,
  locale: 'de',
  messages: {
    en,
    de,
    fr,
    it,
  },
}));
